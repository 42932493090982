var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',{ref:"svg",attrs:{"width":_vm.svgScaling.width,"height":_vm.svgScaling.height,"viewBox":_vm.svgScaling.viewBox,"xmlns":"http://www.w3.org/2000/svg","id":_vm.region},on:{"click":_vm.svgClick}},[_vm._l((_vm.paths),function(countries,continent){return _c('g',{key:continent + 'cc'},_vm._l((countries),function(c,i){return _c('path',{key:i + 'path',class:[{ covered: c.covered }, continent],attrs:{"d":c.d,"fill":"#f7f8fa","stroke":"#BAC9E4","stroke-width":_vm.getScaled(1)}})}),0)}),_vm._l((_vm.circles),function(v,k){return _c('circle',{key:k,attrs:{"cx":v.position[0],"cy":v.position[1],"r":v.radius,"fill":"#11A2B8","fill-opacity":"1","stroke-width":_vm.getScaled(5)},on:{"click":function($event){$event.stopPropagation();return _vm.clickCircle(v, $event)}}})}),(_vm.currentOver)?_c('g',[_c('foreignObject',{staticClass:"shadow",attrs:{"x":_vm.currentOver.position[0],"y":_vm.currentOver.position[1],"width":_vm.currentOver.width,"height":_vm.currentOver.height,"transform":("translate(" + (_vm.currentOver.translateX) + " " + (_vm.currentOver.translateY) + ")"),"id":"popup"}},[_c('div',{staticClass:"popup",style:({ fontSize: ((_vm.getScaled(18)) + "px") }),attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('div',{staticClass:"text",style:({ borderRadius: ((_vm.getScaled(10)) + "px") })},[_c('div',[_c('b',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.currentOver.country.name))]),_c('div',[_vm._v("\n                            "+_vm._s(_vm.$tc("label.percent_users", _vm.currentOver.connected))+"\n                        ")])])])])]),_c('polygon',{staticClass:"shadow",attrs:{"transform":("translate(-" + (_vm.currentOver.width / 2 - 1) + " " + (_vm.currentOver.translateY) + ")"),"points":((_vm.currentOver.position[0] + _vm.currentOver.width / 2 - 1) + "," + (_vm.currentOver
                    .position[1] +
                    _vm.currentOver.height +
                    _vm.getScaled(14)) + " " + (_vm.currentOver.position[0] +
                    _vm.currentOver.width / 2 -
                    _vm.getScaled(14)) + "," + (_vm.currentOver.position[1] +
                    _vm.currentOver.height -
                    1) + " " + (_vm.currentOver.position[0] +
                    _vm.currentOver.width / 2 +
                    _vm.getScaled(14)) + "," + (_vm.currentOver.position[1] + _vm.currentOver.height - 1)),"id":"arrow","fill":"#ffffff"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }